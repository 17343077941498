import { useToast } from '@capturi/ui-components'
import {
  ActionMeta,
  OnChangeValue,
  Select,
  SelectOption,
} from '@capturi/ui-select'
import {
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Skeleton,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { useQuery } from '@tanstack/react-query'
import React, { useMemo } from 'react'
import { MdCopyAll } from 'react-icons/md'

type Props = {
  integrationUid: string
  initialCostumFields: string[]
  onChange: (fields: string[]) => void
  isDisabled: boolean
}

const CustomFieldsSelector: React.FC<Props> = ({
  integrationUid,
  onChange,
  initialCostumFields = [],
  isDisabled,
}) => {
  const toast = useToast()

  const { data: customProps = [], isLoading } = useQuery<
    { id: string; title: string }[]
  >({
    queryKey: [`importer/dixa/${integrationUid}/custom-fields`],
    enabled: !!integrationUid,
  })

  const options = useMemo(
    () =>
      customProps.map((c) => ({
        value: String(c.id),
        label: c.title,
      })),
    [customProps],
  )
  const titleMap = useMemo(
    () => new Map(customProps.map((c) => [c.id, c.title])),
    [customProps],
  )

  const initialValue = useMemo(
    () =>
      initialCostumFields.map((c) => ({
        value: String(c),
        label: titleMap.get(c) || String(c),
      })),
    [initialCostumFields, titleMap],
  )

  const handleChange = (
    values: OnChangeValue<SelectOption, boolean>,
    action: ActionMeta<SelectOption>,
  ): void => {
    if (
      ['select-option', 'remove-value'].includes(action.action) &&
      Array.isArray(values)
    ) {
      onChange(values.map((x) => x.value))
    }
  }

  const handleCopy = (): void => {
    navigator.clipboard.writeText(customProps.map((c) => c.title).join('\n'))
    toast({ status: 'info', title: t`Copied custom fields list to clipboard` })
  }

  return (
    <FormControl>
      <Flex justifyContent="space-between">
        <FormLabel>
          <Trans>Custom Fields (max 10)</Trans>
        </FormLabel>
        <IconButton
          variant="ghost"
          icon={<MdCopyAll />}
          onClick={handleCopy}
          aria-label="copy list content"
        />
      </Flex>
      {isLoading ? (
        <Skeleton height="32px" />
      ) : (
        <Select
          isOptionDisabled={() => initialCostumFields.length >= 10}
          isLoading={isLoading}
          isDisabled={isDisabled}
          defaultValue={initialValue}
          isMulti
          options={options}
          placeholder={t`Select custom fields ...`}
          onChange={handleChange}
          menuPortalTarget={document.body}
          menuPosition="fixed"
          hideSelectedOptions={false}
          backspaceRemovesValue
          isClearable={false}
          autoFocus={false}
          minMenuHeight={100}
          maxMenuHeight={612}
        />
      )}
    </FormControl>
  )
}

export default CustomFieldsSelector
