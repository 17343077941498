import {
  ActionMeta,
  OnChangeValue,
  Select,
  SelectOption,
} from '@capturi/ui-select'
import { FormControl, FormLabel } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { useQuery } from '@tanstack/react-query'
import React from 'react'

import { EtrackDirections } from './useEtrack1Importer'

type Props = {
  integrationUid: string
  initialDirections: string[]
  onChange: (directions: EtrackDirections[]) => void
  isDisabled: boolean
}

const DirectionSelector: React.FC<Props> = ({
  integrationUid,
  onChange,
  initialDirections = [],
  isDisabled,
}) => {
  const { data: directions } = useQuery<string[]>({
    queryKey: [`importer/etrack1/${integrationUid}/directions`],
    enabled: !!integrationUid,
  })

  const options = directions?.map((c) => ({ value: c, label: c }))

  const initialValue = initialDirections.map((c) => ({
    value: c,
    label: c,
  }))

  const handleChange = (
    values: OnChangeValue<SelectOption, boolean>,
    action: ActionMeta<SelectOption>,
  ): void => {
    if (
      ['select-option', 'remove-value'].includes(action.action) &&
      Array.isArray(values)
    ) {
      onChange(values.map((x) => x.value))
    }
  }

  return (
    <FormControl>
      <FormLabel>
        <Trans>Directions</Trans>
      </FormLabel>
      <Select
        isDisabled={isDisabled}
        defaultValue={initialValue}
        isMulti
        options={options}
        placeholder={t`Select Directions to import ...`}
        onChange={handleChange}
        menuPortalTarget={document.body}
        menuPosition="fixed"
        hideSelectedOptions={false}
        backspaceRemovesValue
        isClearable={false}
        autoFocus={false}
        minMenuHeight={100}
        maxMenuHeight={612}
      />
    </FormControl>
  )
}

export default DirectionSelector
